html, body {
  touch-action: none;
  background-color: #334b59;
  margin: 0;
  padding: 0;
  top: 0;
  left: 0;
  overflow: hidden;
}

.activator {
  height: 100%;
  display: block;
  position: fixed;
}

.image {
  height: 100%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.time {
  color: #4a494d;
  min-width: 120px;
  background: #aaa9ad;
  padding: 2px;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 1.5em;
  display: block;
  position: fixed;
  top: 12px;
  right: 0;
}

.time :first-child {
  padding-left: 2px;
  font-weight: bold;
  display: inline-block;
}

.time :last-child {
  padding-right: 2px;
  display: inline-block;
  position: absolute;
  right: 0;
}

@keyframes leftright {
  0% {
    left: 25%;
  }

  50% {
    left: 75%;
  }

  100% {
    left: 25%;
  }
}

#guide .icon {
  z-index: 1000;
  animation-name: leftright;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  display: block;
  position: fixed;
  top: 75%;
  left: 50%;
}

/*# sourceMappingURL=index.c81cac6d.css.map */
