html, body {
    left: 0;
    top: 0;
    margin: 0;
    padding: 0;
    background-color: #334B59;
    overflow: hidden;
    touch-action: none;
}

.activator {
    position: fixed;
    display: block;
    height: 100%;
}

.image {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: center center;
}

.time {
    position: fixed;
    top: 12;
    right: 0;
    background: #AAA9AD;
    color: #4A494D;
    display: block;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 1.5em;
    padding: 2px;
    min-width: 120px;
}

.time *:first-child {
    font-weight: bold;
    display: inline-block;
    padding-left: 2px;
}

.time *:last-child {
    position: absolute;
    right: 0;
    display: inline-block;
    padding-right: 2px;
}

@keyframes leftright {
    0% {
        left: 25%
    }

    50% {
        left: 75%;
    }

    100% {
        left: 25%;
    }
}

#guide .icon {
    position: fixed;
    display: block;
    top: 75%;
    left: 50%;
    z-index: 1000;
    animation-name: leftright;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}
